import { useNavigate } from 'react-router-dom';

import AdminEventCard from '@/components/Events/AdminEventCard';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useGetEventsQuery } from '@/services/eventsApi';
import { pageSizes } from '@/types/Pagination';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { PageHeader } from '@/components/PageHeader';

export default function Events(): JSX.Element {
    const [showOldEvents, setShowOldEvents] = useState(false);
    const currentOrganization = useCurrentOrganization();
    const navigate = useNavigate();
    const { data, isLoading } = useGetEventsQuery({
        pageNumber: 0,
        pageSize: pageSizes.enormous,
        organizationId: currentOrganization?.id,
    });

    if (isLoading) {
        return <CenteredSpinner />;
    }

    const groupNumbers = new Map<string, number>();
    let currentGroupNumber = 1;

    return (
        <div className="flex flex-col items-center">
            <PageHeader>
                <Button onClick={() => navigate('/admin/events/create')}>
                    <Plus className="h-5 w-5 mr-2" />
                    <p>Create New Event</p>
                </Button>
                <Button
                    onClick={() => setShowOldEvents(!showOldEvents)}
                    variant={showOldEvents ? 'default' : 'secondary'}
                    className="transition-none"
                >
                    <p className="w-40">{showOldEvents ? 'Show upcoming events' : 'Show old events'}</p>
                </Button>
            </PageHeader>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl">
                {data?.data &&
                    data.data
                        .filter(
                            (event) =>
                                (showOldEvents && event.endDate && new Date(event.endDate) < new Date()) ||
                                (!showOldEvents && (!event.endDate || new Date(event.endDate) > new Date())),
                        )
                        .map((event) => {
                            let groupNumber: number | undefined;
                            if (event.groupId) {
                                if (!groupNumbers.has(event.groupId)) {
                                    groupNumbers.set(event.groupId, currentGroupNumber++);
                                }
                                groupNumber = groupNumbers.get(event.groupId);
                            }

                            return <AdminEventCard key={event.id} event={event} groupNumber={groupNumber} />;
                        })}
            </div>
        </div>
    );
}
