import type { EventItemSmall, EventItemInput } from './EventItem';
import type { OrganizationSmall } from './Organization';
import type { Place } from './Place';
import type { User } from './User';

export interface Event {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    bannerBlurhash: string | null;
    publishDate: string | null;
    soldOut: boolean;
    ticketsSold: number;
    seatCount: number;
    organization: OrganizationSmall;
    place: Place;
    groupId: string | null;
    eventItems: EventItemSmall[];
    seatMapId: string | null;
    otherRecurring: OtherRec[] | null;
    responsibleUsers: User[];
}

export interface OtherRec {
    id: string;
    startDate: string;
    title: string;
}

export const eventToEventInput = (event: Event | undefined): EventInput | undefined => {
    if (!event) {
        return undefined;
    }
    return {
        description: event.description,
        endDate: event.endDate,
        placeId: event.place.id,
        publishDate: event.publishDate,
        responsibleUserIds: event.responsibleUsers.map((u) => u.id),
        // this should probably be handle in some other way
        // but wont affect the functionality
        seatCount: 0,
        startDate: event.startDate,
        title: event.title,
    };
};

export interface EditEventType {
    id: string;
    description: string;
    endDate: string;
    publishDate: string | null;
    responsibleUserIds: string[];
    startDate: string;
    title: string;
}

export interface EventInput {
    description: string;
    endDate: string;
    placeId: string;
    publishDate: string | null;
    seatCount: number | null;
    startDate: string;
    title: string;
    recurringDates?: string[];
    responsibleUserIds: string[];
    eventItems?: EventItemInput[];
    seatMapId?: string;
}

export interface CreateEventInput extends EventInput {
    organizationId: string;
}

export interface EventSmall {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    bannerBlurhash: string | null;
    publishDate: string | null;
    organizationId: string;
    organizationName: string;
    placeId: string;
    placeName: string;
    groupId: string | null;
    seatMapId: string | null;
}

export interface EventFilter {
    pageNumber?: number;
    pageSize?: number;
    organizationId?: string;
    highBoundaryEndDate?: string;
    lowBoundaryEndDate?: string;
    myEvents?: boolean;
    hideDrafts?: boolean;
}

export interface UploadBanner {
    banner: string;
    id: string;
}

export interface UploadPicture {
    picture: string;
    id: string;
}
