import { cn } from '@/lib/utils';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export const PageHeader: React.FC<Props> = ({ children, className }) => {
    return (
        <div
            className={cn(
                'w-full border-b py-2 px-4 gap-2 items-start flex flex-col md:flex-row flex-shrink-0 justify-between',
                className,
            )}
        >
            {children}
        </div>
    );
};
