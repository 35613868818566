export const PrivacyPolicy = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center w-full h-full text-center">
            <h1 className="text-7xl font-bold h-64 min-h-[16rem] bg-secondary w-full text-white flex justify-center items-center">
                Privacy Policy
            </h1>
            <div className="flex flex-col max-w-3xl text-left gap-4 p-8">
                <p>
                    Privacy Policy for Lunds Akademiska Biljettbyrå (LABB). At LABB, we are committed to protecting the
                    privacy of our users. The Privacy Policy describes how we collect, use, and share information about
                    you when you use our website and mobile application.
                </p>
                <h1 className="text-2xl mt-2">Information we collect</h1>
                <p>We collect information when you use our services. This includes:</p>
                <p>
                    Personal Information: When you create an account with us, we collect your name and email address. If
                    you purchase a ticket through our Services, our payment provider (
                    <a
                        href="https://stripe.com"
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Stripe
                    </a>
                    ) collects your payment information, which may include your credit card number and billing address.
                    We do not store your credit card, but you have the option to save it for future purchases.
                </p>
                <p>
                    Usage Information: We collect information about how you use our services, such as the pages you
                    visit and the features you use. We may also collect information about your device, such as your
                    device type, operating system, and what browser you are using.
                </p>
                <p>
                    Location Information: If granted permission, we may collect your device&apos;s location information
                    to provide you with relevant content and services.
                </p>
                <h1 className="text-2xl mt-2">How we use your information</h1>
                <p>
                    We use your information to provide and improve our services, as well as for other purposes, such as:
                </p>
                <p>
                    Analytics: Collecting information to analyze how our services are used, such as by measuring the
                    effectiveness of our marketing campaigns, improving the user experience and locating app crashes.
                </p>
                <h1 className="text-2xl mt-2">How we share your information</h1>
                <p>
                    We do not sell your information to third party vendors. However, we may share your information with
                    third party vendors for various purposes, such as:
                </p>
                <p>
                    Service Providers: We may share your information with third-party service providers who help us
                    provide and improve our services, such as payment processors and marketing partners.
                </p>
                <p>
                    Nationer/Sektioner: If you are a member of a Nation/Sektion or have followed or purchased an event
                    from them, we may share certain information about you with them.
                </p>
                <p>
                    Analytics: We may share your information with analytics providers to help us improve our Services.
                </p>
                <p>
                    Legal Requirements: We may share your information if we are required to do so by law or if we
                    believe such action is necessary to comply with law or to protect the rights, property, or safety of
                    our users or others.
                </p>
                <h1 className="text-2xl mt-2">Delete your information</h1>
                <p>
                    You can delete your account at any time by contacting us at{' '}
                    <a href="mailto:info@labbs.se" className="text-blue-500 hover:underline">
                        info@labbs.se
                    </a>
                </p>
                <h1 className="text-2xl mt-2">Security</h1>
                <p>
                    We value our users&apos; privacy and strive to protect your information from unauthorized access,
                    use, and disclosure. We do not store passwords, we use one time passwords instead.
                </p>
                <p>
                    We do not store any payment card details and instead use{' '}
                    <a
                        href="https://stripe.com"
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Stripe
                    </a>
                    .
                </p>
                <p>
                    However, no method of transmission over the Internet or method of electronic storage is 100% secure,
                    so we cannot guarantee its absolute security.
                </p>
                <h1 className="text-2xl mt-2">Changes to this Privacy Policy</h1>
                <p>
                    We may update this Privacy Policy from time to time. If we make material changes to this Privacy
                    Policy, we will notify you by email or by posting a notice on our website or mobile application.
                </p>
                <h1 className="text-2xl mt-2">Contact us</h1>
                <p>
                    If you have any questions or concerns about this Privacy, please contact us at{' '}
                    <a href="mailto:info@labbs.se" className="text-blue-500 hover:underline">
                        info@labbs.se
                    </a>
                </p>
            </div>
        </div>
    );
};
