import EventScanCard from '@/components/Events/EventScanCard';
import OrganizationSelector from '@/components/Organizations/OrganizationSelector';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganization, useMyOrganizations } from '@/hooks/apiHooks';
import { useGetScannableEventsQuery } from '@/services/eventsApi';

export default function ScannList(): JSX.Element {
    const currentOrganization = useCurrentOrganization();
    const myOrganizations = useMyOrganizations();

    const { data, error, isLoading } = useGetScannableEventsQuery({
        pageNumber: 0,
        pageSize: 50,
        hideDrafts: true,
        organizationId: currentOrganization?.id,
    });

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (error) {
        return <div>Error loading events.</div>;
    }

    return (
        <div className="w-full md:w-3/5 lg:w-1/2 flex flex-col space-y-4 justify-center p-4 space md:py-4 mx-auto">
            {myOrganizations.length > 1 && (
                <div className="border rounded-md">
                    <OrganizationSelector />
                </div>
            )}
            <h1 className="text-2xl font-bold">Scannable Events</h1>
            {data && data.data.length > 0 ? (
                <ul className="space-y-4">
                    {data.data.map((event) => (
                        <EventScanCard key={event.id} event={event} />
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500">No scannable events available.</p>
            )}
        </div>
    );
}
