import { cn } from '@/lib/utils';
import { Children, Fragment, type ReactElement, type ReactNode } from 'react';
import { Separator } from '@/components/ui/separator';

interface StepProps {
    name: string;
    disabled?: boolean;
    children: ReactNode;
    headerItems?: ReactNode;
}

export function Step({ children }: StepProps): JSX.Element {
    return <>{children}</>;
}

interface StepperProps {
    children: ReactElement<StepProps>[];
    currentStep: number;
    setCurrentStep: (index: number) => void;
    onClick?: () => void;
}

export const Stepper = ({ children, currentStep, setCurrentStep, onClick }: StepperProps): JSX.Element => {
    const selectedStep = children[currentStep];
    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex items-center gap-1 flex-shrink-0 p-2 w-full bg-background">
                {children.map((step, index) => {
                    const last = index === Children.toArray(children).length - 1;
                    return (
                        <Fragment key={step.props.name}>
                            <button
                                className={cn(
                                    'flex flex-row items-center space-x-1 hover:text-accent-foreground hover:bg-accent py-1 px-2 rounded-md cursor-pointer',
                                    {
                                        'cursor-not-allowed opacity-50': step.props.disabled,
                                        'bg-secondary hover:bg-secondary cursor-default': currentStep === index,
                                    },
                                )}
                                onClick={() => {
                                    if (!step.props.disabled && currentStep !== index) {
                                        setCurrentStep(index);
                                        onClick?.();
                                    }
                                }}
                            >
                                <p
                                    className={cn('h-6 w-6 text-l rounded-full flex items-center justify-center p-4', {
                                        'bg-secondary text-secondary-foreground': currentStep > index,
                                        'bg-background': currentStep <= index,
                                    })}
                                >
                                    {index + 1}
                                </p>
                                <p
                                    className={cn({
                                        'text-secondary-foreground': currentStep === index,
                                        'text-base': currentStep !== index,
                                    })}
                                >
                                    {step.props.name}
                                </p>
                            </button>
                            {!last && <Separator orientation="vertical" className="h-4" />}
                        </Fragment>
                    );
                })}
                {selectedStep?.props.headerItems && (
                    <div className="flex-1 flex justify-end">{selectedStep.props.headerItems}</div>
                )}
            </div>
            <Separator />
            {children.map((step, index) => {
                return (
                    <div
                        className={cn('flex flex-col flex-1', {
                            hidden: index !== currentStep,
                        })}
                        key={index + step.toString()}
                    >
                        {step}
                    </div>
                );
            })}
        </div>
    );
};
