import { useAuth, useCurrentOrganization } from '@/hooks/apiHooks';

import { ModeToggle } from './ModeToggle';
import UserMenu from './UserMenu';
import OrganizationSelector from './Organizations/OrganizationSelector';
import { Link, NavLink } from 'react-router-dom';
import { BasicTooltip } from './ui/tooltip';
import { PreviewImage } from './PreviewImage';
import {
    ORGANIZATION_PICTURE_ASPECT_RATIO,
    ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH,
    ORGANIZATION_PICTURE_PLACEHOLDER_SRC,
    getOrganizationPictureSrc,
} from '@/lib/utils';

export default function AdminHeader(): JSX.Element {
    const { isAuthenticated } = useAuth();
    const currentOrg = useCurrentOrganization();

    return (
        <header className="w-full bg-primary z-10 h-12 border-b flex justify-between items-center px-4 sticky top-0 left-0 text-primary-foreground">
            <div className="flex items-center">
                <NavLink to="/" className="hover:underline text-lg font-semibold mr-8">
                    LABB
                </NavLink>
            </div>
            <div className="flex items-center gap-2 h-full">
                <OrganizationSelector />
                <BasicTooltip tooltip="View Organization" side="bottom">
                    <Link to="/admin/organization" className="flex items-center justify-center">
                        <div className="w-10 h-10 hover:bg-primary/10 rounded-sm flex justify-center items-center">
                            <PreviewImage
                                url={
                                    currentOrg?.pictureBlurhash
                                        ? getOrganizationPictureSrc(currentOrg?.id)
                                        : ORGANIZATION_PICTURE_PLACEHOLDER_SRC
                                }
                                blurHash={currentOrg?.pictureBlurhash ?? ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH}
                                className="h-8 w-8 rounded-sm m-2"
                                aspectRatio={ORGANIZATION_PICTURE_ASPECT_RATIO}
                            />
                        </div>
                    </Link>
                </BasicTooltip>
                <ModeToggle />
                {isAuthenticated && <UserMenu />}
            </div>
        </header>
    );
}
