import type { CreateEventInput, EditEventType, Event, EventFilter, EventSmall, UploadBanner } from '@/types/Event';
import { pageSizes, type Pagination } from '@/types/Pagination';

import { api } from './rootApi';
import { imageToJPEG } from '@/lib/cropUtils';
import type { SeatMap } from '@/types/SeatMap';
import { kebabCase } from '@/lib/utils';
import type { Ticket } from '@/types/Ticket';

type EventResponse = Event[];

export const eventsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<Pagination<EventSmall>, EventFilter>({
            query: ({
                pageNumber = 0,
                pageSize = pageSizes.enormous,
                organizationId,
                hideDrafts,
                highBoundaryEndDate,
                lowBoundaryEndDate,
            }) =>
                `events?pageNumber=${pageNumber}&pageSize=${pageSize}&showDraft=${!hideDrafts}${
                    organizationId ? `&organizationId=${organizationId}` : ''
                }${highBoundaryEndDate ? `&highBoundaryEndDate=${highBoundaryEndDate}` : ''}${
                    lowBoundaryEndDate ? `&lowBoundaryEndDate=${lowBoundaryEndDate}` : ''
                }
                    `,

            providesTags: ['Event'],
        }),
        getEventById: builder.query<Event, string>({
            query: (id) => `events/${id}`,
            providesTags: ['Event', 'EventItem'],
        }),
        getEventSeatMapById: builder.mutation<SeatMap, string>({
            query: (id) => `events/${id}/seat-map`,
        }),
        createEvent: builder.mutation<EventResponse, CreateEventInput>({
            query: (body) => ({
                url: `events`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Event'],
        }),
        editEvent: builder.mutation<void, EditEventType>({
            query: ({ id, ...body }) => ({
                url: `events/${id}/edit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Event'],
        }),
        deleteEvent: builder.mutation<void, string>({
            query: (id) => ({
                url: `events/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Event'],
        }),
        getEventAttendees: builder.query<Ticket[], string>({
            query: (id) => `events/${id}/attendees`,
        }),
        getEventAttendeesCSV: builder.query<void, { eventId: string; title: string }>({
            query: ({ eventId, title }) => ({
                url: `events/${eventId}/attendees/csv`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const file = new File([blob], `${kebabCase(title)}-attendees.csv`, { type: blob.type });
                    return window.location.assign(window.URL.createObjectURL(file));
                },
                cache: 'no-cache',
            }),
        }),
        uploadBanner: builder.mutation<void, UploadBanner>({
            query: (body) => {
                const formData = new FormData();
                formData.append('id', body.id);
                const bannerFile = imageToJPEG(body.banner, 'banner.jpeg');
                formData.append('banner', bannerFile);
                return {
                    url: `upload/banner`,
                    method: 'POST',
                    body: formData,
                    prepareHeaders: (headers: { delete: (arg0: string) => void }) => {
                        headers.delete('Content-Type');
                        return headers;
                    },
                };
            },
        }),
        getScannableEvents: builder.query<Pagination<EventSmall>, EventFilter>({
            query: ({
                pageNumber = 0,
                pageSize = pageSizes.enormous,
                organizationId,
                hideDrafts,
                highBoundaryEndDate,
                lowBoundaryEndDate,
            }) =>
                `events/scannable?pageNumber=${pageNumber}&pageSize=${pageSize}&showDraft=${!hideDrafts}${
                    organizationId ? `&organizationId=${organizationId}` : ''
                }${highBoundaryEndDate ? `&highBoundaryEndDate=${highBoundaryEndDate}` : ''}${
                    lowBoundaryEndDate ? `&lowBoundaryEndDate=${lowBoundaryEndDate}` : ''
                }`,

            providesTags: ['Event'],
        }),
    }),
});

/**
 * This is actually a query, but it's named as a mutation because it's ran every time and not cached
 */
export const useGetEventSeatMapByIdQuery = eventsApi.useGetEventSeatMapByIdMutation;

export const {
    useGetEventsQuery,
    useGetEventByIdQuery,
    useCreateEventMutation,
    useDeleteEventMutation,
    useUploadBannerMutation,
    useEditEventMutation,
    useLazyGetEventAttendeesCSVQuery,
    useGetEventAttendeesQuery,
    useGetScannableEventsQuery,
} = eventsApi;
