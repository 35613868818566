export const About = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center w-full h-full text-center">
            <h1 className="text-2xl lg:text-7xl font-bold h-64 min-h-[16rem] bg-secondary w-full text-white flex justify-center items-center">
                LABB - Lunds Akademiska BiljettByrå
            </h1>
            <div className="flex flex-col max-w-3xl text-left gap-4 p-8 justify-center items-center">
                <p>
                    Welcome to LABB. We are an "Ideell Studentförening", (which means we are a non-profit student
                    organization) founded in the great student city of Lund, Sweden. We at LABB were not happy with the
                    slow, buggy and unavailable ticket systems used for the many student events in the city. That is why
                    we decided to create our own.
                    <br />
                    <br />
                    Our goal is to create the best ticketing sales platform in Lund so that student events can be
                    created and organized simply by anyone.
                </p>
            </div>
        </div>
    );
};
