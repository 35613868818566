import type { Ticket } from '@/types/Ticket';
import { Card } from '@/components/ui/card';
import { cn, formatDate, formatPrice } from '@/lib/utils';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import QRCode from 'qrcode.react';
import type { EventSmall } from '@/types/Event';

interface TicketCardProps {
    event: EventSmall;
    tickets: Ticket[];
}

export default function TicketCard({ event, tickets }: TicketCardProps): JSX.Element {
    return (
        <Drawer>
            <DrawerTrigger className="w-full text-left">
                <Card className="flex flex-col justify-between shadow-none p-2 md:p-4 h-32">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-semibold">{event.title}</h2>
                    </div>
                    <div>
                        <p>{event.organizationName}</p>
                        <p>{event.placeName}</p>
                        <div className="flex gap-1 md:gap-2 text-sm">
                            <p>{formatDate(event.startDate)}</p>
                            <p>-</p>
                            <p>{formatDate(event.endDate)}</p>
                        </div>
                    </div>
                </Card>
            </DrawerTrigger>
            <DrawerContent className="flex w-full">
                <div className="flex flex-col gap-1 p-4 md:p-8">
                    <h2 className="text-2xl md:text-4xl font-semibold">{event.title}</h2>
                    <p className="font-medium">{event.organizationName}</p>
                    <p>{event.placeName}</p>
                    <div className="flex space-x-2 text-sm">
                        <p>{formatDate(event.startDate)}</p>
                        <p>-</p>
                        <p>{formatDate(event.endDate)}</p>
                    </div>
                </div>
                <div
                    className={cn(
                        'flex gap-8 snap-x snap-mandatory py-8 px-16',
                        tickets.length > 1 && 'overflow-x-auto',
                    )}
                >
                    {tickets.map((ticket) => (
                        <div
                            key={ticket.id}
                            className="flex flex-col text-center snap-center h-full justify-center items-center gap-2"
                        >
                            {ticket.status === 'CONSUMED' && <p className="text-2xl text-green-500">Consumed</p>}
                            <QRCode size={256} value={ticket.id} />
                            <p className="text-lg">
                                {ticket.eventItem.name} - {formatPrice(ticket.actualPrice)} SEK
                            </p>
                            <div className="">
                                {ticket.seat.row && ticket.seat.seatNumber && ticket.seat.category && (
                                    <div className="text-center">
                                        <p className="font-medium text-xl">
                                            Seat: row {ticket.seat.row} number {ticket.seat.seatNumber}
                                        </p>
                                        <p className="text-lg">{ticket.seat.category.name}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </DrawerContent>
        </Drawer>
    );
}
