import { ShoppingCartIcon } from 'lucide-react';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useAuth } from '@/hooks/apiHooks';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '@/lib/utils';
import { useGetCartByUserIdQuery } from '@/services/cartApi';
import { useEffect, useState } from 'react';
import type { Ticket } from '@/types/Ticket';
import { errorToastFromCatch } from './ui/use-toast';

export default function CartMenu(): JSX.Element {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [fetchCart] = useGetCartByUserIdQuery();
    const [data, setData] = useState<Ticket[]>();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (user) {
            fetchCart(user.id)
                .unwrap()
                .then((cart) => {
                    setData(cart);
                })
                .catch(errorToastFromCatch);
        }
    }, [user, fetchCart, isOpen]);

    return (
        <DropdownMenu onOpenChange={(open) => setIsOpen(open)}>
            <DropdownMenuTrigger className="flex space-x-2 items-center min-w-fit">
                <div className="cursor-pointer relative inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-2 py-1 h-10 space-x-1 hover:bg-gray-500/20">
                    <ShoppingCartIcon className="h-5 w-5" /> <p>Cart</p>
                    {data && data.length > 0 && (
                        <div className="absolute top-0 -right-1 bg-accent text-accent-foreground rounded-full w-4 h-4 flex items-center justify-center">
                            <p className="text-sm">{data.length}</p>
                        </div>
                    )}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuLabel>Cart</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <div className="max-h-96 overflow-y-auto">
                    {!data?.length && <DropdownMenuLabel className="font-normal">Cart is empty</DropdownMenuLabel>}
                    {data?.map((item) => (
                        <DropdownMenuItem
                            key={item.id}
                            className="cursor-pointer space-x-8"
                            onClick={() => navigate(`/events/${item.event.id}`)}
                        >
                            <div>
                                <p className="font-semibold">{item.event.title}</p>
                                <p>{item.eventItem.name}</p>
                            </div>
                            <p>{formatPrice(item.actualPrice)} SEK</p>
                        </DropdownMenuItem>
                    ))}
                </div>
                <DropdownMenuSeparator />
                <DropdownMenuItem className="cursor-pointer" onClick={() => navigate('/cart')}>
                    <p className="text-center w-full font-semibold">View cart</p>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
