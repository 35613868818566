import { cn } from '@/lib/utils';

interface Props {
    checked: boolean;
    onClick: () => void;
    className?: string;
    hidden?: boolean;
}

export const Switch = ({ checked, onClick, className, hidden }: Props): JSX.Element => {
    return (
        <div className={className}>
            <div
                className={cn(
                    'h-[26px] w-[48px] rounded-full box-content flex-shrink-0 p-[2px] transition-color hover:cursor-pointer',
                    {
                        'bg-secondary': checked,
                        'bg-accent': !checked,
                    },
                )}
                onClick={onClick}
                hidden={hidden}
            >
                <div
                    className={cn('h-[26px] w-[26px] transition-transform rounded-full bg-background shadow-lg', {
                        'translate-x-[22px]': checked,
                    })}
                />
            </div>
        </div>
    );
};
