import {
    type ColumnDef,
    type ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getSortedRowModel,
    type OnChangeFn,
    type PaginationState,
    type SortingState,
    useReactTable,
    type VisibilityState,
} from '@tanstack/react-table';
import { type HTMLAttributes, useState } from 'react';

import { DataTablePagination } from '@/components/dataTable/DataTablePagination';
import { DataTableToolbar } from '@/components/dataTable/DataTableToolbar';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import type { Pagination } from '@/types/Pagination';

type DataTableProps<TData, TValue> = {
    columns: ColumnDef<TData, TValue>[];
    data: Pagination<TData>;
    pagination: PaginationState;
    setPagination: OnChangeFn<PaginationState>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: OnChangeFn<ColumnFiltersState>;
    pageCount: number;
    rowActions?: (row: TData) => JSX.Element;
    hideViewOptions?: boolean;
    hideFilters?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function DataTable<TData, TValue>({
    columns,
    data,
    setPagination,
    pagination,
    columnFilters,
    setColumnFilters,
    pageCount,
    rowActions,
    hideViewOptions,
    hideFilters,
    ...props
}: DataTableProps<TData, TValue>): JSX.Element {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const table = useReactTable({
        data: data.data,
        columns,
        state: {
            sorting,
            columnVisibility,
            columnFilters,
            pagination,
        },
        pageCount: pageCount,
        onPaginationChange: setPagination,
        enableColumnFilters: true,
        manualFiltering: true,
        manualPagination: true,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    return (
        <div {...props}>
            {!hideFilters && <DataTableToolbar table={table} hideViewOptions={hideViewOptions} />}
            <div className="w-full">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} isHeader>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            <div className="flex justify-between items-center">
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                            </div>
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                    {rowActions && <TableCell>{rowActions(row.original)}</TableCell>}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <DataTablePagination table={table} totalElements={data.totalElements} />
        </div>
    );
}
