import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import type { EventSmall } from '@/types/Event';
import { useDeleteEventMutation } from '@/services/eventsApi';
import { DeleteDialog } from '../ui/DeleteDialog';
import { Button } from '../ui/button';
import {
    getEventBannerSrc,
    EVENT_BANNER_PLACEHOLDER_SRC,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_ASPECT_RATIO,
    formatDate,
} from '@/lib/utils';
import { PreviewImage } from '../PreviewImage';
import { MapPinIcon } from 'lucide-react';

interface Props {
    event: EventSmall;
    groupNumber?: number;
}

export default function AdminEventCard({ event, groupNumber }: Props): JSX.Element {
    const [deleteEvent, deleteEventState] = useDeleteEventMutation();
    const navigate = useNavigate();

    return (
        <Card
            className="flex h-full flex-col hover:bg-accent hover:cursor-pointer relative"
            onClick={() => navigate(`/admin/events/${event.id}`)}
        >
            {groupNumber && (
                <div className="absolute top-2 left-2 z-10 bg-secondary text-secondary-foreground px-2 py-0.5 rounded text-sm">
                    Group {groupNumber}
                </div>
            )}
            <PreviewImage
                url={event.bannerBlurhash ? getEventBannerSrc(event.id) : EVENT_BANNER_PLACEHOLDER_SRC}
                blurHash={event.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                className="rounded-t-lg w-full"
                aspectRatio={EVENT_BANNER_ASPECT_RATIO}
            />
            <CardHeader>
                <CardTitle>{event.title}</CardTitle>
                <div className="flex gap-2">
                    <p>{formatDate(event.startDate)}</p>
                    <p>-</p>
                    <p>{formatDate(event.endDate)}</p>
                </div>
                <CardDescription className="flex gap-1 items-center">
                    <MapPinIcon className="h-3 w-3" />
                    {event.placeName}
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-4 flex-1">
                <p className="line-clamp-3">{event.description}</p>
            </CardContent>
            <CardFooter className="justify-end gap-4">
                <Button
                    variant={
                        !event.publishDate ? 'orange' : new Date() > new Date(event.publishDate) ? 'green' : 'lightBlue'
                    }
                >
                    {!event.publishDate
                        ? 'Draft'
                        : new Date() > new Date(event.publishDate)
                          ? 'Published'
                          : 'Scheduled'}
                </Button>
                <DeleteDialog
                    deleteItem={() => deleteEvent(event.id).unwrap()}
                    isLoading={deleteEventState.isLoading}
                    successMessage="Event deleted successfully"
                >
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </CardFooter>
        </Card>
    );
}
