import { Button } from '@/components/ui/button';
import { useGetCartByUserIdQuery, useRemoveTicketFromCartMutation } from '@/services/cartApi';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { useAuth } from '@/hooks/apiHooks';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '@/components/CheckoutForm';
import { useEffect, useMemo, useState } from 'react';
import { ArrowLeftIcon, TrashIcon } from 'lucide-react';
import { useTheme } from '@/components/ThemeProvider';
import { formatDate, formatPrice } from '@/lib/utils';
import { useCreatePaymentIntentMutation } from '@/services/paymentApi';
import { isPaidPaymentIntentResponse, type PaidPaymentIntentResponse } from '@/types/Payment';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { CenteredSpinner } from '@/components/ui/spinner';
import type { Ticket } from '@/types/Ticket';
import FoodPreferences from '@/components/ui/food-preferences';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

type RemovingRecord = Record<string, boolean>;
type FoodPreferencesRecord = Record<string, string[]>;

export default function Cart(): JSX.Element {
    const navigate = useNavigate();
    const [createPaymentIntent, response] = useCreatePaymentIntentMutation();
    const [removeItem] = useRemoveTicketFromCartMutation();
    const { theme } = useTheme();
    const { user } = useAuth();
    const [fetchCart] = useGetCartByUserIdQuery();
    const [paymentIntent, setPaymentIntent] = useState<PaidPaymentIntentResponse | null>(null);
    const [data, setData] = useState<Ticket[]>();
    const [foodPreferences, setFoodPreferences] = useState<FoodPreferencesRecord>({});
    const referralCode = localStorage.getItem('eventReferral');

    useEffect(() => {
        if (user) {
            fetchCart(user.id)
                .unwrap()
                .then((cart) => {
                    setData(cart);
                    setFoodPreferences(
                        cart.reduce((prefs, ticket) => {
                            prefs[ticket.id] = ticket.foodPreferences || [];
                            return prefs;
                        }, {} as FoodPreferencesRecord),
                    );
                })
                .catch(errorToastFromCatch);
        }
    }, [user, fetchCart]);

    const [isRemoving, setIsRemoving] = useState<RemovingRecord>(
        data?.reduce((obj: RemovingRecord, ticket) => {
            obj[ticket.id] = false;
            return obj;
        }, {}) ?? {},
    );

    const total = useMemo(() => {
        return data?.reduce((acc, item) => acc + item.actualPrice, 0) ?? 0;
    }, [data]);

    const deleteTicket = async (id: string) =>
        removeItem(id)
            .unwrap()
            .then(() => {
                if (user) {
                    return fetchCart(user.id).unwrap();
                }
                return undefined;
            })
            .then((cart) => {
                setData(cart);
            })
            .catch(errorToastFromCatch);

    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: total,
        currency: 'sek',
        paymentMethodTypes: ['card', 'swish'],
        appearance: {
            theme: theme === 'dark' ? 'night' : 'stripe',
            variables: {
                colorPrimary: '#000000',
            },
        },
    };

    function handleRemoveItem(id: string) {
        setIsRemoving((prev) => ({ ...prev, [id]: true }));
        deleteTicket(id)
            .then(() => {
                setIsRemoving((prev) => ({ ...prev, [id]: false }));
            })
            .catch(errorToastFromCatch);
    }

    function handleCheckout() {
        if (data) {
            createPaymentIntent({
                currency: 'sek',
                data: data.map((ticket) => ({
                    foodPreferences: foodPreferences[ticket.id] ?? [],
                    ticketId: ticket.id,
                })),
                stripeVersion: '2023-10-16',
                referralCode: referralCode || undefined,
            })
                .unwrap()
                .then((paymentIntentResponse) => {
                    if (isPaidPaymentIntentResponse(paymentIntentResponse)) {
                        setPaymentIntent(paymentIntentResponse);
                    } else {
                        successToast(paymentIntentResponse.message);
                        navigate('/order/complete?redirect_status=succeeded');
                    }
                })
                .catch(errorToastFromCatch);
        } else {
            errorToast('Please add tickets to cart before checking out');
        }
    }

    if (data === undefined) {
        return <CenteredSpinner />;
    }

    return (
        <div className="flex justify-center mt-4 md:mt-8 mx-4">
            <div className="w-full lg:max-w-5xl">
                {!paymentIntent ? (
                    <>
                        <h1 className="text-3xl font-semibold mb-4">Cart</h1>
                        {data && !data.length && <p className="text-center text-2xl font-medium py-8">Cart is empty</p>}
                        {data?.map((item) => (
                            <div className="flex w-full space-x-2 mb-2 items-center" key={item.id}>
                                <div
                                    className="flex justify-between cursor-pointer flex-1 items-center hover:bg-secondary p-2 rounded-lg transition-colors duration-200 ease-in-out"
                                    onClick={() => navigate(`/events/${item.event.id}`)}
                                >
                                    <div>
                                        <p className="font-semibold">{item.event.title}</p>
                                        <p className="text-sm">{formatDate(item.event.startDate)}</p>
                                        <p>{item.eventItem.name}</p>
                                        {item.seat.row && item.seat.seatNumber && item.seat.category && (
                                            <p className="text-md">
                                                {item.seat.category.name} row {item.seat.row} number{' '}
                                                {item.seat.seatNumber}
                                            </p>
                                        )}
                                    </div>
                                    <p>{formatPrice(item.actualPrice)} SEK</p>
                                </div>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button variant="secondary">Food Preference</Button>
                                    </DialogTrigger>
                                    <DialogContent>
                                        <FoodPreferences
                                            foodPreferences={foodPreferences[item.id] ?? []}
                                            setFoodPreferences={(newPreferences) => {
                                                setFoodPreferences({
                                                    ...foodPreferences,
                                                    [item.id]: newPreferences,
                                                });
                                            }}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Button
                                    size="icon"
                                    variant="destructive"
                                    isLoading={isRemoving[item.id]}
                                    onClick={() => handleRemoveItem(item.id)}
                                >
                                    <TrashIcon className="h-5 w-5" />
                                </Button>
                            </div>
                        ))}
                        <div className="border-t pt-2">
                            <h2 className="text-xl font-semibold mb-4 text-right">Total</h2>
                            <div className="space-y-2">
                                <p className="text-xl text-right">{formatPrice(total)} SEK</p>
                                <Button
                                    className="w-full"
                                    disabled={!data?.length || response.isLoading}
                                    isLoading={response.isLoading}
                                    onClick={handleCheckout}
                                >
                                    Checkout
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="w-full space-y-4">
                        <Button variant="ghost" className="space-x-2" onClick={() => setPaymentIntent(null)}>
                            <ArrowLeftIcon className="h-5 w-5" /> <p>Go back</p>
                        </Button>
                        <h2 className="text-2xl font-semibold">Checkout</h2>
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm paymentIntent={paymentIntent} total={total} />
                        </Elements>
                    </div>
                )}
            </div>
        </div>
    );
}
