import { Outlet } from 'react-router-dom';

import { Toaster } from '@/components/ui/toaster';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';

export const Root = (): JSX.Element => {
    return (
        <div className="w-screen h-screen flex flex-col">
            <div className="flex-shrink-0">
                <Header />
            </div>
            <div className="flex flex-col flex-1">
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="flex-grow">
                        <Outlet />
                    </div>
                    <div className="flex-shrink-0 mt-4">
                        <Footer />
                    </div>
                </div>

                <Toaster />
            </div>
        </div>
    );
};
