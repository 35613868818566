import TicketCard from '@/components/Tickets/TicketCard';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuth } from '@/hooks/apiHooks';
import { useGetUserTicketsQuery } from '@/services/ticketsApi';
import { useMemo } from 'react';
import type { GroupedTickets, Ticket } from '@/types/Ticket';

export default function Tickets(): JSX.Element {
    const { user } = useAuth();
    const { data, isLoading } = useGetUserTicketsQuery(user?.id ?? '');

    const formatedTickets: GroupedTickets[] = useMemo(() => {
        if (!data) {
            return [];
        }
        // Group tickets by event ID using a Map
        const groupedTickets = data.reduce((acc, ticket) => {
            const eventId = ticket.event.id;
            if (!acc.has(eventId)) {
                acc.set(eventId, []);
            }
            acc.get(eventId)?.push(ticket);
            return acc;
        }, new Map<string, Ticket[]>());

        // Create a set of unique events
        const events = new Set(
            Array.from(groupedTickets.keys())
                .map((eventId) => data.find((ticket) => ticket.event.id === eventId)?.event)
                .filter((event) => event !== undefined),
        );

        // Map the events to the desired structure
        const result = Array.from(events)
            .map((event) => {
                if (!event) {
                    return;
                }
                return {
                    event,
                    unsedTickets: groupedTickets.get(event.id)?.filter((ticket) => ticket.status === 'TAKEN') ?? [],
                    consumedTickets:
                        groupedTickets.get(event.id)?.filter((ticket) => ticket.status === 'CONSUMED') ?? [],
                };
            })
            .filter((groupedTicket) => groupedTicket !== undefined) as GroupedTickets[];

        return result;
    }, [data]);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching tickets</div>;
    }

    return (
        <div className="flex justify-center mt-4 md:mt-8 mx-4">
            <div className="w-full lg:max-w-5xl">
                <h1 className="text-3xl font-semibold mb-4">My Tickets</h1>
                <Tabs defaultValue="active">
                    <TabsList>
                        <TabsTrigger value="active">Unused Tickets</TabsTrigger>
                        <TabsTrigger value="consumed">Used Tickets</TabsTrigger>
                    </TabsList>
                    <TabsContent value="active">
                        <div className="space-y-2">
                            {formatedTickets
                                .filter((groupedTicket) => groupedTicket.unsedTickets.length > 0)
                                .map((groupedTicket) => (
                                    <div key={groupedTicket.event.id}>
                                        <TicketCard event={groupedTicket.event} tickets={groupedTicket.unsedTickets} />
                                    </div>
                                ))}
                        </div>
                    </TabsContent>

                    <TabsContent value="consumed">
                        <div className="space-y-2">
                            {formatedTickets
                                .filter((groupedTicket) => groupedTicket.consumedTickets.length > 0)
                                .map((groupedTicket) => (
                                    <div key={groupedTicket.event.id}>
                                        <TicketCard
                                            event={groupedTicket.event}
                                            tickets={groupedTicket.consumedTickets}
                                        />
                                    </div>
                                ))}
                        </div>
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
}
